@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

:root {
    --cart-overlay-border-radius: 25px;
}

[dir='ltr'] .CartOverlay {
    &-CheckoutButton,
    &-CartButton {
        &,
        &:hover,
        &:focus {
            border-radius: 25px;
        }
    }
    @include desktop {
        top: calc(100% + 10px);
    }
}
