@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2c/src/style/abstract/variables';
@import '../../../../advox-strigo-b2c/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2c/src/style/abstract/button';

.AddToCart {
    --button-height: var(--button-height-small);
    --button-hover-height: var(--button-hover-height-small);
    --button-border-radius: 5px;
    --button-hover-border-radius: 5px;
    box-shadow: none;

    @include mobile {
        min-width: auto;
    }

    span {
        font-size: 13px;
        line-height: 169%;
        font-weight: var(--primary-font-weight-bold);
    }

    svg {
        margin-right: 12px;
        width: 23px;
        height: 20px;
        fill: $white;
        stroke: $white;
    }

    &[disabled] {
        svg {
            fill: var(--button-disabled-color);
            stroke: var(--button-disabled-color);
        }
    }
}
