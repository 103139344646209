@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2c/src/style/abstract/variables';
@import '../../../../advox-strigo-b2c/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2c/src/style/abstract/button';

:root {
    --cart-discount-icon-color: #{$default-secondary-base-color};
}

.CartPage {
    --cart-page-table-head-background: #{$grey-light};
    overflow-x: hidden;
    margin-top: 0;
    padding: 0;

    .CheckoutOrderSummary-Header {
        display: none;
    }
    .CheckoutOrderSummary {
        @include desktop {
            padding: 0;
        }

        .Checkout-Heading span {
            display: none;
        }
    }
    .ProductLinks-SliderWrapper {
        max-width: var(--content-wrapper-width);
        margin: 0 auto;
        padding: 0 var(--content-padding);
    }
    &-Items {
        @include mobileAndTablet {
            margin-bottom: 65px;
        }
        @include desktop {
            margin-bottom: 100px;
        }
    }
    &-Wrapper {
        @include tablet {
            padding: 0;
        }
        @include desktop {
            grid-template-columns: 1fr 394px;
            grid-column-gap: 27px;
        }
    }

    &-Summary {
        &.FixedElement-Bottom {
            @include tablet {
                padding: 0 var(--content-padding);
                background-color: $grey-light;
            }

            .CheckoutOrderSummary-ButtonWrapper {
                @include tablet {
                    padding: 0;
                }
            }
        }
    }
    &-Floating {
        @include tablet {
            margin-top: 35px;
        }
        @include desktop {
            margin-top: 0;
        }
    }
    &-TableHead {
        font-weight: normal;
        font-size: 15px;
        line-height: 150%;
        font-family: $font-secondary;
        border-radius: 5px;
        color: $black;
        @include mobileAndTablet {
            display: none;
        }

        @include desktop {
            padding: 11px var(--content-padding);
            grid-template-columns: minmax(100px, 4fr) 2fr 1fr 80px;
            grid-gap: 0;
        }
    }

    &-Heading {
        font-family: $font-primary;
        font-weight: bold;
        font-size: 22px;
        line-height: 135%;
        letter-spacing: 0.07em;
        color: $default-primary-base-color;
        margin: 25px 0 0;
        padding: 10px var(--content-padding);

        @include mobile {
            display: flex;
        }

        @include tablet {
            padding: 10px var(--content-padding);
        }

        @include desktop {
            font-size: 30px;
            padding: 10px 0;
            margin: 0;
        }

        span {
            color: $grey-dark;
            @include mobile {
                margin-left: 5px;
            }
        }
    }

    .CheckoutSteps {
        @include desktop {
            margin-bottom: 22px;
        }

        @include mobileAndTablet {
            padding-bottom: 24px;
            padding-left: var(--content-padding);
            padding-right: var(--content-padding);
        }
    }

    .AdvoxFreeDelivery {
        padding: 0;
        margin: 17px var(--content-padding);

        svg {
            margin-left: 17px;
            @include desktop {
                margin-left: 32px;
            }
        }

        @include desktop {
            margin: 17px 0;
        }
    }

    &-Discount {
        border: 1px solid $grey5;
        border-radius: 10px;
        padding: 16.5px 0;
        margin: 65px var(--content-padding) 0;

        @include mobile {
            padding: 2.5px 0;
        }

        @include tablet {
            padding: 16.5px 0;
        }

        @include desktop {
            margin: 16px 0 0;
        }
        .CartPage-ExpandableContentButton {
            cursor: pointer;
            > svg {
                display: none;
            }
        }

        .ExpandableContent-Button {
            &_isContentExpanded {
                .CartPage-ExpandableContentHeading svg {
                    &:last-of-type {
                        transform: rotate(180deg);
                    }
                }
            }
        }
        .CartPage-ExpandableContentHeading {
            font-family: $font-primary;
            font-weight: bold;
            font-size: 15px;
            line-height: 140%;
            text-align: right;
            letter-spacing: 0.07em;
            color: $default-primary-base-color;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            cursor: pointer;

            span {
                display: block;
                width: 100%;
                text-align: left;
            }
            svg {
                path {
                    stroke: var(--cart-discount-icon-color);
                }
                &:first-of-type {
                    height: 18px;
                    margin-right: 16px;
                    min-width: 18px;
                    margin-left: 32px;

                    @include mobileAndTablet {
                        margin-left: 17px;
                    }
                }
                &:last-of-type {
                    transition: rotate 0.25s ease-in-out;
                    width: 14px;
                    height: 14px;
                    margin-right: 32px;
                    @include mobileAndTablet {
                        margin-right: 17px;
                    }
                }
            }
        }

        .CartPage-ExpandableContentContent {
            display: none;

            &_isContentExpanded {
                display: block;
            }
        }
    }
    &-CheckoutButton {
        font-family: $font-primary;
        letter-spacing: 0.07em;
        font-weight: bold;
        font-size: 15px;
        line-height: 140%;
        margin: 25px 0 0;
        svg {
            margin-left: 16px;
            width: 14px;
            height: 14px;

            path {
                stroke: $white;
            }
        }
    }
}
