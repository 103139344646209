@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

:root {
    --qty-background: #{$purple5};
    --qty-button-border-radius: 50%;
    --qty-button-height: 32px;
    --qty-button-width: 32px;
    --qty-button-color: #{$white};
    --qty-button-background: #{$default-primary-base-color};
    --qty-button-background-disabled: #{$purple20};
    --qty-button-border-disabled: #{$purple20};
    --qty-button-border-color: #{$default-primary-base-color};
    --qty-input-background: #{$purple5};
    --qty-input-color: #{$default-primary-base-color};
}

[dir='ltr'] .CartItem {
    --qty-buttons-padding: 5px;
    &-Qty {
        @include qty-input;
    }
}
