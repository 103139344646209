@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

.AddToCart {
    --button-border-radius: 100px;
    --button-hover-border-radius: 100px;
    --button-hover-background: #{$default-primary-base-color};
    --button-hover-border: #{$default-primary-base-color};
    --add-to-cart-primary-color: #{$default-primary-base-color};
    --button-border: #{$purple};
    --button-height: var(--default-button-height);
    --button-hover-height: var(--default-button-height);
    --add-to-cart-primary-color-light: #{$purple};
    span {
        font-family: $font-primary;
        font-weight: normal;
        font-size: 14px;
        line-height: 157%;
        letter-spacing: -0.4px;
    }
    &_isFullOnHover {
        --button-height: var(--button-height-small);
        --button-hover-height: var(--button-height-small);
        min-width: unset;
        width: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: var(--button-height-small);
        background-color: var(--add-to-cart-primary-color-light);
        transition: all 0.25s ease-in-out;
        border-width: 1px;
        svg {
            margin-right: 0;
            path {
                stroke: var(--add-to-cart-primary-color);
            }
        }
        span {
            display: inline-block;
            max-width: 0;
            overflow: hidden;
        }

        &:disabled {
            --button-border: #{$grey-light};
            background-color: $grey-light;
            svg {
                path {
                    stroke: $white;
                }
            }
        }
    }
}
